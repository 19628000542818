// External imports
import React from "react";
import { Container, Row, Col } from "reactstrap";
// Internal imports
import LoginForm from "../components/LoginForm";
import { AppState } from "../components/App";

interface ApiLoginProps {
  appState: AppState;
  setAppState: (appState: AppState) => void;
}

const ApiLogin = (props: ApiLoginProps) => {
  const { appState, setAppState } = props;
  // Style the login page, pass appState to LoginForm component
  return (
    <div className="homebox">
      <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
        <Row>
          <Col className="text-center">
            <h1>twinLab</h1>
            <LoginForm appState={appState} setAppState={setAppState} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApiLogin;
