import { AppState } from "./components/App";

interface modelsResponse {
  emulators: string[];
}

export async function getModelsResponse(
  endpoint: string,
  user: string,
  apiKey: string,
): Promise<modelsResponse | null> {
  const url = `${endpoint}/emulators`;
  const params: RequestInit = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-user": user,
      "x-api-key": apiKey,
    },
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) {
      throw new Error(`HTTP error, status = ${response.status}`);
    }
    const responseData: modelsResponse = await response.json();
    // Sort the emulators array in alphabetical order
    responseData.emulators.sort((a, b) => a.localeCompare(b));

    return responseData;
  } catch (error) {
    console.error("Error:", error);
    return null; // Return null to indicate an error occurred
  }
}

export function getInputs(summaryDict: any): {
  parameterNames: string[];
} {
  const parameterNames = Object.keys(
    summaryDict.summary.data_diagnostics.inputs,
  );
  return {
    parameterNames,
  };
}

export function getInputsStats(summaryDict: any): {
  inputsStats: { [key: string]: any };
} {
  const inputs: { [key: string]: any } =
    summaryDict.summary.data_diagnostics.inputs;
  let inputsStats: { [key: string]: any } = {};
  for (const key in inputs) {
    if (inputs.hasOwnProperty(key)) {
      const inputStats = inputs[key];
      inputsStats[key] = {
        mean: inputStats.mean,
        min: inputStats.min,
        max: inputStats.max,
      };
    }
  }
  return {
    inputsStats,
  };
}

export function getOutputs(summaryDict: any): {
  outputNames: string[];
} {
  const outputNames: string[] = Object.keys(
    summaryDict.summary.data_diagnostics.outputs,
  );
  return {
    outputNames,
  };
}

export async function getSummaryResponse(
  selectedModel: string,
  endpoint: string,
  user: string,
  apiKey: string,
): Promise<any> {
  if (selectedModel === "") {
    console.warn("No model selected");
    return null; // Return null or an appropriate value when no model is selected
  }
  const url = `${endpoint}/emulators/${selectedModel}/summary`;
  const params: RequestInit = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-api-key": apiKey,
      "x-user": user,
    },
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) {
      throw new Error(`HTTP error, status = ${response.status}`);
    }
    let summaryDict = await response.text();
    // Now parse the JSON string
    summaryDict = JSON.parse(summaryDict);
    // setAppState({ summaryData: summaryDict });
    return summaryDict;
  } catch (error) {
    console.error("Error:", error);
    return null; // Return null to indicate an error occurred
  }
}

export async function postEmulatorPredict(
  selectedModel: string,
  endpoint: string,
  user: string,
  apiKey: string,
  dataset: any,
): Promise<string> {
  const url = `${endpoint}/emulators/${selectedModel}/predict`;
  const requestBody = {
    dataset: dataset,
    observation_noise: true,
  };
  const params: RequestInit = {
    method: "POST",
    headers: {
      accept: "application/json",
      "x-api-key": apiKey,
      "x-user": user,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) {
      console.error("HTTP error: ", response);
    }
    const responseText = await response.text();
    const responseDict = JSON.parse(responseText);
    const processId = responseDict.process_id;
    const predictions = await getEmulatorPredict(
      selectedModel,
      endpoint,
      user,
      apiKey,
      processId,
    );
    return predictions;
  } catch (error) {
    console.error("Error:", error);
    return "null"; // Return null to indicate an error occurred
  }
}

async function checkProcessStatus(
  endpoint: string,
  selectedModel: string,
  process_id: string,
  user: string,
  apiKey: string,
): Promise<string> {
  const url = `${endpoint}/emulators/${selectedModel}/processes/${process_id}`;
  const params: RequestInit = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-api-key": apiKey,
      "x-user": user,
    },
  };
  const response = await fetch(url, params);
  if (!response.ok) {
    throw new Error(`HTTP error, status = ${response.status}`);
  }
  const responseText = await response.text();
  const responseDict = JSON.parse(responseText);
  return responseDict.status; // Assuming the status is in responseDict.status
}

export async function getEmulatorPredict(
  selectedModel: string,
  endpoint: string,
  user: string,
  apiKey: string,
  process_id: string,
): Promise<any> {
  const pollingInterval = 5000; // 5 seconds
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  // Polling loop
  while (true) {
    const status = await checkProcessStatus(
      endpoint,
      selectedModel,
      process_id,
      user,
      apiKey,
    );
    if (status === "success") {
      break;
    }
    if (status === "failed") {
      throw new Error("Process failed");
    }
    await delay(pollingInterval);
  }

  const url = `${endpoint}/emulators/${selectedModel}/processes/${process_id}`;
  const params: RequestInit = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-api-key": apiKey,
      "x-user": user,
    },
  };
  try {
    const response = await fetch(url, params);
    // if (!response.ok) {
    //     throw new Error(`HTTP error, status = ${response.status}`);
    // }
    const responseText = await response.text();
    const responseDict = JSON.parse(responseText);
    return responseDict;
  } catch (error) {
    console.error("Error:", error);
    return null; // Return null to indicate an error occurred
  }
}

interface Data {
  summary_data: any;
  x_axis: string | undefined;
  y_axis: string | undefined;
  input_variables: any;
}

interface Prediction {
  campaign_id: string;
  output_variable: string | undefined;
  input_variables: any;
  x_axis: string | undefined;
  y_axis: string | undefined;
  reference: number;
  request_time: number;
  predictions: any;
  dataset: string;
}

export async function predictModelResponse(
  inputVariables: any,
  appState: AppState,
): Promise<any> {
  const url =
    "https://c7zkwujzyaihsy4wzg5vlv7jpi0dyjqf.lambda-url.eu-west-2.on.aws/api";
  const evalData: Data = {
    summary_data: appState.summaryData,
    x_axis: appState.xAxisParam,
    y_axis: appState.yAxisParam,
    input_variables: inputVariables,
  };
  if (appState.plotType === "1D plot") {
    evalData["y_axis"] = "none";
  }
  const eval_params = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(evalData),
  };

  try {
    const evalResponse = await fetch(`${url}/data`, eval_params);
    const evalResponseData = await evalResponse.json();
    const dataset: string = evalResponseData.df;

    const postPredict = await postEmulatorPredict(
      appState.selectedModel,
      appState.endpoint,
      appState.user,
      appState.apiKey,
      dataset,
    );

    const predict_data: Prediction = {
      campaign_id: appState.selectedModel,
      output_variable: appState.outputVar,
      input_variables: inputVariables,
      x_axis: evalResponseData.x_vals,
      y_axis: evalResponseData.y_vals,
      reference: 0,
      request_time: 0,
      predictions: postPredict,
      dataset: dataset,
    };
    const predict_params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(predict_data),
    };
    const predictResponse = await fetch(`${url}/prediction`, predict_params);
    const predictResponseData = await predictResponse.json();

    return predictResponseData;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

export interface UserInfo {
  user: string;
  apiKey: string;
}

export async function isUser(
  endpointUrl: string,
  user: string,
  apiKey: string,
  verbose: boolean = false,
): Promise<boolean> {
  const url = `${endpointUrl}/user`;
  const params: RequestInit = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-user": user,
      "x-api-key": apiKey,
    },
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) {
      throw new Error(`HTTP error, status = ${response.status}`);
    }
    return true;
  } catch (error) {
    return false;
  }
}
