// External imports
import React from "react";
import { Grid } from "@mui/material";
// Internal imports
import { AppState } from "./App";
import Plot1D from "./Plot1D";
import ContourPlot2D from "./ContourPlot2D";
import HeatmapPlot2D from "./HeatmapPlot2D";

interface PlotPanelProps {
  appState: AppState;
  setAppState: (appState: AppState) => void;
}

const PlotPanel = (props: PlotPanelProps) => {
  const { appState, setAppState } = props;

  return (
    <Grid container justifyContent="center" spacing={2}>
      <React.Fragment>
        {/* 1D Plot - Full width */}
        {appState.plotType === "1D plot" && (
          <Grid item xs={12} width={12}>
            <div className="heatmap-panel">
              <Plot1D appState={appState} setAppState={setAppState} />
            </div>
          </Grid>
        )}

        {/* 2D Contour Plot - Two side-by-side plots */}
        {appState.plotType === "2D plot" && (
          <>
            <Grid item xs={6}>
              <ContourPlot2D
                appState={appState}
                setAppState={setAppState}
                isUncert={false}
              />
            </Grid>
            <Grid item xs={6}>
              <ContourPlot2D
                appState={appState}
                setAppState={setAppState}
                isUncert={true}
              />
            </Grid>
          </>
        )}

        {/* 2D Heatmap - Two side-by-side plots
        {appState.plotType === "2D heatmap" && (
          <>
            <Grid item xs={6}>
              <HeatmapPlot2D
                appState={appState}
                setAppState={setAppState}
                isUncert={false}
              />
            </Grid>
            <Grid item xs={6}>
              <HeatmapPlot2D
                appState={appState}
                setAppState={setAppState}
                isUncert={true}
              />
            </Grid>
          </>
        )}  */}
      </React.Fragment>
    </Grid>
  );
};

export default PlotPanel;
