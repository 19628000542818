import React, { useState } from "react";
import ApiLogin from "../pages/ApiLogin";
import ModelVisualisation from "../pages/ModelVisualisation";

// Store useful info in the AppState
export interface AppState {
  showDebugPanel: boolean;
  endpoint: string;
  user: string;
  apiKey: string;
  page: string;
  selectedModel: string;
  plotType: string | undefined;
  outputVar: string | undefined;
  xAxisParam: string | undefined;
  yAxisParam: string | undefined;
  xVals: number[];
  yVals: number[];
  zVals: number[];
  zLower1Sig: number[];
  zUpper1Sig: number[];
  zLower2Sig: number[];
  zUpper2Sig: number[];
  zStd: number[];
  summaryData: any;
}

const App = () => {
  // Initial appState when app is opened
  const [appState, setAppState] = useState<AppState>({
    showDebugPanel: false,
    endpoint: "https://twinlab.digilab.co.uk/v3",
    user: "",
    apiKey: "",
    page: "login",
    selectedModel: "",
    plotType: "1D plot",
    outputVar: undefined,
    xAxisParam: undefined,
    yAxisParam: undefined,
    xVals: [],
    yVals: [],
    zVals: [],
    zLower1Sig: [],
    zUpper1Sig: [],
    zLower2Sig: [],
    zUpper2Sig: [],
    zStd: [],
    summaryData: null,
  });
  // Determine the page to render using the appState
  if (appState.page === "login") {
    return (
      <div>
        <ApiLogin appState={appState} setAppState={setAppState} />
      </div>
    );
  } else if (appState.page === "model_visualisation") {
    return (
      <div>
        <ModelVisualisation appState={appState} setAppState={setAppState} />
      </div>
    );
  }

  return <div>Something went wrong...</div>;
};

export default App;
