// @ts-nocheck
// Above comment removes typescript typechecking, without it
// the `type` parameter in plotData gives an error for plotly
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { AppState } from "./App";
import MathJax from 'react-mathjax2'

interface ContourPlot2DProps {
  appState: AppState;
  setAppState: (appState: AppState) => void;
  isUncert: boolean;
}

const ContourPlot2D = (props: ContourPlot2DProps) => {
  const { appState, setAppState, isUncert } = props;

  const defaultLayout = {
    widthRatio: 0.35, // Set default width here
    heightRatio: 0.7, // Set default height here
    contourColor: "YlGnBu", // Set default contour map color here
  };

  const axisLabelMap: { [key: string]: string } = {
    "ky": "$k_y$",
    "q": "$q$",
    "shat": "$\\hat{s}$",
    "electron_dens_gradient": "$a/L_n$",
    "deuterium_temp_gradient": "$a/L_{Ti}$",
    "beta": "$\\beta$",
    "electron_nu": "$\\nu_{ei}$",
    "electron_temp_gradient": "$a/L_{Te}$"
  };

  const getAxisLabel = (label: string) => {
    return axisLabelMap[label] || label;
  };

  // Set default plot data
  const [plotData, setPlotData] = useState([
    {
      type: "contour",
      x: [],
      y: [],
      z: [],
      reversescale: true,
      hoverongaps: false,
      colorbar: {
        title: "Prediction",
      },
    },
  ]);

  // Set default plot layout
  const [plotLayout, setPlotLayout] = useState({
    title: "Prediction",
    width: window.innerWidth * defaultLayout.widthRatio,
    height: window.innerHeight * defaultLayout.heightRatio,
    xaxis: {
      title: "",
    },
    yaxis: {
      title: "",
    },
    zaxis: {
      title: "",
    },
  });

  // Update plot layout on window resize
  useEffect(() => {
    const handleResize = () => {
      setPlotLayout((prevLayout) => ({
        ...prevLayout,
        width: window.innerWidth * defaultLayout.widthRatio,
        height: window.innerHeight * defaultLayout.heightRatio,
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update plot data and plot layout whenever appState changes
  useEffect(() => {
    setPlotData([
      {
        type: "contour",
        x: appState.xVals,
        y: appState.yVals,
        // Plot STD for uncertainty plots, otherwise plot the mean
        z: isUncert ? appState.zStd : appState.zVals,
        colorscale: isUncert ? [[0, '#009fe3'], [1, 'white']] :defaultLayout.contourColor,
        reversescale: true,
        hoverongaps: false,
        colorbar: {
          title: appState.outputVar || "",
          x: 1.01, // Position the colorbar to the right of the plot
          y: 0.5, // Center the colorbar vertically
          thickness: 40, // Thickness of the colorbar
        },
      },
    ]);
    setPlotLayout({
      ...plotLayout,
      // Change the plot title depending on whether uncertainty plot
      margin: { // Maximize plot space
        l: window.innerWidth * 0.03,
        r: window.innerWidth * 0.001,
        t: window.innerHeight * 0.04,
        b: window.innerHeight * 0.05, 
      },
      title: isUncert ? " Uncertainty (standard deviation)" : "Prediction (mean)",
      xaxis: {
        title:  getAxisLabel(appState.xAxisParam || "")|| "",
      },
      yaxis: {
        title: {
          text:  getAxisLabel(appState.yAxisParam || "") || "",
        },
        range: [Math.min(...appState.yVals), Math.max(...appState.yVals)],
      },
      zaxis: {
        title: getAxisLabel(appState.outputVar || "") || "",
      },
    });
  }, [appState]);

  // Return the plotly Plot component with current data and layout
  return (
    <MathJax.Context input='tex'>
      <div>
        <Plot
          data={plotData}
          layout={plotLayout}
        />
      </div>
    </MathJax.Context>
  );
};

export default ContourPlot2D;
