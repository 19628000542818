// @ts-nocheck
// Above comment removes typescript typechecking, without it
// the `type` parameter in plotData gives an error for plotly
import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { AppState } from "./App";
import MathJax from 'react-mathjax2';

interface Plot1DProps {
  appState: AppState;
  setAppState: (appState: AppState) => void;
}

const Plot1D = (props: Plot1DProps) => {
  const { appState, setAppState } = props;

  const defaultLayout = {
    widthRatio: 0.7, // Set default width here
    heightRatio: 0.85, // Set default height here
    color: "125,185,40", // Set default color here (currently set to digilab green)
  };

  const axisLabelMap: { [key: string]: string } = {
    "ky": "$k_y$",
    "q": "$q$",
    "shat": "$\\hat{s}$",
    "electron_dens_gradient": "$a/L_n$",
    "deuterium_temp_gradient": "$a/L_{Ti}$",
    "beta": "$\\beta$",
    "electron_nu": "$\\nu_{ei}$",
    "electron_temp_gradient": "$a/L_{Te}$"
  };

  const getAxisLabel = (label: string) => {
    return axisLabelMap[label] || label;
  };

  // Set default plot data
  const [plotData, setPlotData] = useState([
    {
      type: "scatter",
      x: [],
      y: [],
      fillcolor: `rgba(${defaultLayout.color},0.2)`,
      line: { color: `rgb(${defaultLayout.color})` },
      mode: "lines",
      name: "mean",
      showlegend: true,
    },
  ]);

  // Set default plot layout
  const [plotLayout, setPlotLayout] = useState({
    //title: appState.selectedModel,
    width: window.innerWidth * defaultLayout.widthRatio,
    height: window.innerHeight * defaultLayout.heightRatio,
    margin: { // Maximize plot space
      l: window.innerWidth * 0.03,
      r: window.innerWidth * 0.01,
      t: window.innerHeight * 0.01, 
      b: window.innerHeight * 0.1, 
    },
    xaxis: {
      title: "",
      range: [0, 1],
    },
    yaxis: {
      title: "",
      range: [0, 1],
    },
  });

  // Update plot layout on window resize
  useEffect(() => {
    const handleResize = () => {
      setPlotLayout((prevLayout) => ({
        ...prevLayout,
        width: window.innerWidth * defaultLayout.widthRatio,
        height: window.innerHeight * defaultLayout.heightRatio,
      }));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Update plot data and plot layout whenever appState changes
  useEffect(() => {
    setPlotData([
      // Solid mean line
      {
        type: "scatter",
        x: appState.xVals,
        y: appState.zVals, // Future humans could rename appState.zVals to appState.outputVals for clarity
        fillcolor: `rgb(${defaultLayout.color},0.2)`,
        line: { color: `rgb(${defaultLayout.color})` },
        name: "Mean",
        mode: "lines",
        showlegend: true,
      },
      // 1 Sigma uncertainty shaded region
      {
        x: appState.xVals.concat(appState.xVals.slice().reverse()),
        y: appState.zLower1Sig.concat(appState.zUpper1Sig.slice().reverse()),
        fill: "toself",
        fillcolor: `rgba(${defaultLayout.color},0.4)`,
        line: { color: "transparent" },
        name: "68% Confidence",
        showlegend: true,
        type: "scatter",
      },
      // 2 Sigma uncertainty shaded region
      {
        x: appState.xVals.concat(appState.xVals.slice().reverse()),
        y: appState.zLower2Sig.concat(appState.zUpper2Sig.slice().reverse()),
        fill: "toself",
        fillcolor: `rgba(${defaultLayout.color},0.2)`,
        line: { color: "transparent" },
        name: "95% Confidence",
        showlegend: true,
        type: "scatter",
      },
    ]);
    setPlotLayout({
      ...plotLayout,
      //title: "Prediction",
      xaxis: {
        title: getAxisLabel(appState.xAxisParam || ""),
      },
      yaxis: {
        title: getAxisLabel(appState.outputVar || ""),
      },
      legend: { // move legend to white space on graph
        x: 0.95, 
        y: 0.95, 
        xanchor: "right", 
        yanchor: "top", 
      },
    });
  }, [appState]);

  // Return the plotly Plot component with current data and layout
  return (
    <MathJax.Context input='tex'>
      <div>
        <Plot
          data={plotData}
          layout={plotLayout}
        />
      </div>
    </MathJax.Context>
  );
};

export default Plot1D;
