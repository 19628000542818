// External imports
import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
// Internal imports
import "./styles/index.css";
import App from "./components/App";

initializeIcons();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
