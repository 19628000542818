// External imports
import React, { useState } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
// Internal imports
import { AppState } from "../components/App";
import { isUser } from "../utils";

interface LoginFormProps {
  appState: AppState;
  setAppState: (appState: AppState) => void;
}

const LoginForm = (props: LoginFormProps) => {
  const { appState, setAppState } = props;
  const [loading, setLoading] = useState(false);
  const [wrongUser, setWrongUser] = useState(false);

  const handleUserChange = (event: any) => {
    setAppState({ ...appState, user: event.target.value });
  };

  const handleApiKeyChange = (event: any) => {
    setAppState({ ...appState, apiKey: event.target.value });
  };

  const tryLogin = () => {
    setLoading(true);

    isUser(appState.endpoint, appState.user, appState.apiKey).then(
      (response) => {
        if (response) {
          setWrongUser(false);
          setAppState({ ...appState, page: "model_visualisation" });
          setLoading(false);
        } else {
          setWrongUser(true);
          setLoading(false);
        }
      },
    );
  };

  return (
    <div>
      <TextField
        placeholder="Enter your email"
        onChange={handleUserChange}
        className="text-field"
      />
      <br />
      <TextField
        type="password"
        canRevealPassword
        revealPasswordAriaLabel="Show password"
        placeholder="Enter your API key"
        onChange={handleApiKeyChange}
        className="text-field"
      />
      <br />
      <div style={{ textAlign: "center" }}>
        <p style={{ visibility: wrongUser ? "visible" : "hidden" }}>
          {" "}
          Incorrect user information. Please try again.{" "}
        </p>
        <DefaultButton
          iconProps={{ iconName: "Unlock" }}
          onClick={() => tryLogin()}
          disabled={loading}
        >
          Login
        </DefaultButton>
      </div>
    </div>
  );
};

export default LoginForm;
